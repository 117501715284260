<template>
 <div ref="home" class="homeM">   
          
       <div  class="xinzeng" >
               <el-button type="primary"  size="mini"   @click="clickBtn">角色管理</el-button> 
       </div> 
      <el-table :data="tableData.slice((currentPage-1)* pageSize,currentPage*pageSize)" 
      :header-cell-style="{ textAlign: 'center' }" 
      height="450"
       :cell-style="{ textAlign: 'center' }" > 
              <el-table-column prop="id" label="序号"></el-table-column>
              <el-table-column prop="xingqi" label="竞彩编号"></el-table-column>
              <el-table-column prop="saishi" label="赛事名称"></el-table-column>
              <el-table-column prop="zhudui" label="主队"></el-table-column>
              <el-table-column prop="kedui" label="客队"> </el-table-column>
              <el-table-column prop="" label="原有场次ID">
                <template>  
                    <span >——</span>
                  </template>
              </el-table-column>
              <el-table-column prop="jc_time" label="更新时间"></el-table-column>
              <el-table-column prop="pk_type" label="vip场次ID" ></el-table-column>
              <el-table-column prop="p" label="外盘口" > </el-table-column>
              <el-table-column prop="o" label="大球" width="180"> </el-table-column>
              <!-- <el-table-column prop="name" label="大球">
                  <template slot-scope="scope">
                  <el-form :model="scope.row">
                    <el-form-item size="mini" label-width="180">
                      <el-input v-model="scope.row.name"   @blur="Inputdx(scope.row.name,scope.row.id)"  style="width:100%;hight:100%"></el-input> 
                    </el-form-item>
                  </el-form>
                </template>
              </el-table-column>   -->
        </el-table>
        <!-- 分页器 -->
        <div class="block">
            <el-pagination align='center' @size-change="handleSizeChange" @current-change="handleCurrentChange" 
            :current-page="currentPage" 
            :page-sizes="[15,30,60]" 
            :page-size="pageSize" 
            layout="total, sizes, prev, pager, next" 
            :total="tableData.length">
            </el-pagination> 
        </div> 
        
  </div>


</template>
 
<script>
// @ is an alias to /src 
export default {
    beforeCreate () {
    this.$pom('home', 'homeP', this) // 此处需要三个参数，第一个为最大盒子ref名，
//第二个为最大盒子的pc端类名，第三个直接写this，因为插件要找到vue.component实列
  },
  name: "Home",
  components: { 
  },
  data(){
       return {
          loading: true,
          tableData: [],
          currentPage: 1, // 当前页码
          total: 0, // 总条数
          pageSize: 15 ,// 每页的数据条数
          

                };
            },
 mounted(){   
     this.getList();  
  }, 
  
 methods: {
   rowStyle() {
      return "text-align:center";
    },
   dbclick(row){
      row.isOK =!row.isOK
    },
   // eslint-disable-next-line
            tableRowClassName({row, rowIndex}) {
                  if (rowIndex %2 === 0) {
                    return 'warning-row';
                  } 
                  return '';
                },
 
        clickBtn(){ 
          let routeUrl = this.$router.resolve({
          path: "/management",
        
        });
         window.open(routeUrl.href, '_blank') //关键在此
        },
       
       InputBlur(e,s){ 
           console.log(e,s,"----");
              //  this.$axios({
              //       method: 'post', 
              //       url: 'http://t.guocaisd.com/getdata.php',
              //       data: this.$qs.stringify({          //axios的post方法访问后端this.qs.stringify(
              //        id: "ttg",
              //         
              //       }), 
              //   })
              // .then(res=>{                    //请求成功后执行函数 
              //     this.tableData=res.data.data.list; 
             
              //       if(res.data.code == 205){ 
              //           this.$router.push('/login')   
              //      }
              // });  
       },
       Inputpk(e,s){ 
           console.log(e,s,"----");
              //  this.$axios({
              //       method: 'post', 
              //       url: 'http://t.guocaisd.com/getdata.php',
              //       data: this.$qs.stringify({          //axios的post方法访问后端this.qs.stringify(
              //        id: "ttg",
              //         
              //       }), 
              //   })
              // .then(res=>{                    //请求成功后执行函数 
              //     this.tableData=res.data.data.list;  
              //       if(res.data.code == 205){ 
              //           this.$router.push('/login')   
              //      }
              // });  
       },
       Inputdx(e,s){ 
           console.log(e,s,"----");
              //  this.$axios({
              //       method: 'post', 
              //       url: 'http://t.guocaisd.com/getdata.php',
              //       data: this.$qs.stringify({          //axios的post方法访问后端this.qs.stringify(
              //        id: "ttg",
              //       
              //       }), 
              //   })
              // .then(res=>{                    //请求成功后执行函数 
              //     this.tableData=res.data.list; 
              // 
              //       if(res.data.code == 205){ 
              //           this.$router.push('/login')   
              //      }
              // });  
       },
      
     
      getList(){  
            this.$axios({
                    method: 'post', 
                    url: 'http://t.guocaisd.com/getdata2.php',
                    data: this.$qs.stringify({ //axios的post方法访问后端this.qs.stringify(
                      function:"list" 
                    }), 
                })
              .then(res=>{                    //请求成功后执行函数  
                console.log(res.data.data.list,"======");
                  this.tableData=res.data.data.list; 
                   this.loading=false;
                    // if(res.data.code == 205){
         
                    // this.$notify({
                    //             title: '提示',
                    //             message: '登录超时请重新登录',
                    //             duration: 3000
                    //           });
                    //               this.$router.push('/login')   
                    //     }
              })
      },
       
        //每页条数改变时触发 选择一页显示多少行
        handleSizeChange(val) { 
            this.pageSize = val;
            this.getList(this.jc,this.cr);  
          
        },
        //当前页改变时触发 跳转其他页
        handleCurrentChange(val) { 
            this.currentPage = val; 
        }
    } 

};
</script>


<style lang="scss" scoped>
.homeM{
    width: 100%;height: 100%;
   position: relative;
  
   .el-table .el-table__cell{
      padding: 12px 0 ;
    }
    .xinzeng{
    height: 0.75rem;
    margin-left: 81%;
    margin-top: 10px;
    display: flex;
  }
  .el-pagination__total{
    margin-right: 0 !important;
  }
  .el-table{
    padding: 0;
  } 

}
 
.homeP{ 
  font-size: 12px !important;
   position: relative;
   .el-table {
     width: 100% !important;
     margin-left: -40px;
   }
    
    .xinzeng{ 
    margin-left: 90%;
    margin-top: 10px;
    display: flex;
  }
  @media screen and (min-width:300px) and (max-width: 1200px){
  .el-table__body-wrapper{
    overflow-x: auto !important;
  }
} 
}
 

 
  
</style>
