<template>
  <div ref="details" class="detailsM">
       <div class="headers">
          <div class="header_top">
              <div class="top_left">
                <div>{{form.saishi}}</div>
                <div>{{form.xingqi}}</div>
                <div>{{form.start_time}}</div>
              </div>
              <div class="top_right">
                <div class="right_text">{{form.zhudui}}</div>
                <div class="vs">VS</div>
                <div class="right_text">{{form.kedui}}</div>
              </div>
          </div>
          <div class="header_buttom">
              <div class="buttom_item">
                  <div class="item_text">竞猜返点</div>
                  <div class="item_ipt"> <input @blur="InputBlur(form.jc)" class="home_header_ipt" type="text" v-model="form.jc" value="form.jc"> </div>
              </div>
              <div class="buttom_item">
                  <div class="item_text">crown</div>
                  <div class="item_ipt"> <input @blur="InputBlurs(form.cr)" class="home_header_ipt" type="text" v-model="form.cr" value="form.cr"> </div>
              </div>
              <div class="buttom_item">
                  <!-- <div class="item_text">类型</div>
                  <div class="item_lei"> 
                    <div class="leixin" v-if="form.p&&form.p=='2' || form.p=='3'">让0</div>
                    <div class="leixin" v-if="form.p&&form.p=='2/2.5' || form.p=='3/3.5'">让平半</div>
                    <div class="leixin" v-if="form.p&&form.p=='1.5' || form.p=='2.5' || form.p=='3.5'">生死</div>
                    <div class="leixin" v-if="form.p&&form.p=='1.5/2' || form.p=='2.5/3'">受让平半</div>
                  </div> -->
                   <div class="item_text">类型</div>
                  <div class="item_lei"> 
                      <div class="leixin" >——</div> 
                  </div>
              </div>
              <div class="buttom_item">
                  <div class="item_text">嘉奖</div>
                  <div class="item_lei"> <div class="jiajiang">——</div> </div>
              </div>
          </div> 
      </div>
       <div class="main" v-if="detailsqwe == 1">
                               <table 
                        class="InspectionSituationqwe" 
                      
                        cellspacing="0"
                        cellpadding="0" 
                    >  
                       <thead >
                          <tr  class="trclass" style="background-color: #fff;"> 
                            <th colspan=""></th>
                            <th colspan=""></th> 
                            <th  style="width:13%; background:#fff; text-align: center;">赔率</th> 
                            <th style="width:16%; background:#fff; text-align: center;">下注金额</th> 
                            <th style="width:16%; background:#fff; text-align: center;">奖金</th> 
                            <th  style="width:16%; background:#fff; text-align: center;">赢反水</th> 
                            <th  style="width:16%; background:#fff; text-align: center;">输反水</th>   
                        </tr>
                        </thead> 
                        <tbody> 
                            <tr class="trclass">
                                <td style="width:6%; background: #F7FBFF;text-align: center;"></td>
                                <td style="width:10%; background:#fff; text-align: center;">0</td>
                                <td style="width:14%; background:#fff; text-align: center;">
                                    <input @blur="IptBlurP(odds0)" class="home_header_ipt" type="text" v-model="odds0" value="odds0"> 
                                </td>   
                                <td style="width:14%; background:#fff; text-align: center;">
                                     <span  style="margin-left: 10px">{{odds0_money}}</span>     
                                </td>
                                <td style="width:14%; background:#fff; text-align: center;">
                                     <span   v-if="list.zero && list.zero.bonus" style="margin-left: 10px">{{list.zero.bonus}}</span> 
                                     <span v-else style="margin-left: 10px">-</span>
                                </td>
                                <td style="width:14%; background:#fff; text-align: center;">
                                     <span v-if="list.zero"  style="margin-left: 10px">{{list.zero.win}}</span> 
                                     <span v-else style="margin-left: 10px">-</span>
                                </td>
                                <td style="width:14%; background:#fff; text-align: center;">
                                      <span  v-if="list.zero"  style="margin-left: 10px">{{list.zero.lose}}</span> 
                               <span v-else style="margin-left: 10px">-</span>
                                </td> 
                              
                            </tr>
                             <tr class="trclass">
                                 <td style="width:6%; background: #F7FBFF; text-align: center;"></td>
                                <td style="width:10%; background:#fff; text-align: center;">1</td>
                                <td style="width:14%; background:#fff; text-align: center;">
                                    <input @blur="InputBlurP1(odds1)" class="home_header_ipt" type="text" v-model="odds1" value="odds1"> 
                                </td>
                                <td style="width:14%; background:#fff; text-align: center;">
                                      <span   style="margin-left: 10px">{{odds1_money}}</span> 
                                </td>
                                <td style="width:14%; background:#fff; text-align: center;">
                                      <span  v-if="list.one" style="margin-left: 10px"> {{list.one.bonus}}</span> 
                                      <span v-else style="margin-left: 10px">-</span>
                                </td>
                                <td style="width:14%; background:#fff; text-align: center;">
                                     <span  v-if="list.one" style="margin-left: 10px"> {{list.one.win}}</span>  
                                     <span v-else style="margin-left: 10px">-</span>
                                </td>
                                <td style="width:14%; background:#fff; text-align: center;">
                                       <span   v-if="list.one"   style="margin-left: 10px"> {{list.one.lose}}</span> 
                             <span v-else style="margin-left: 10px">-</span>
                                </td>
                             </tr>
                             <tr class="trclass">
                                 <td style="width:6%; background: #F7FBFF; text-align: center;"></td>
                                <td style="width:10%; background:#fff; text-align: center;">2</td>
                                <td style="width:14%; background:#fff; text-align: center;">
                                    <input  @blur="InputBlurP2(odds2)" class="home_header_ipt" type="text" v-model="odds2" value="odds2"> 
                                </td>
                                <td style="width:14%; background:#fff; text-align: center;">
                                    <span   style="margin-left: 10px">{{odds2_money}}</span> 
                                </td>
                                <td style="width:14%; background:#fff; text-align: center;">
                                        <span   v-if="list.two"   style="margin-left: 10px">{{list.two.bonus}}</span> 
                                        <span v-else style="margin-left: 10px">-</span> 
                                </td>
                                <td style="width:14%; background:#fff; text-align: center;">
                                      <span  v-if="list.two"  style="margin-left: 10px">{{list.two.win}}</span> 
                                      <span v-else style="margin-left: 10px">-</span>
                                </td>
                                <td style="width:14%; background:#fff; text-align: center;">
                                     <span  v-if="list.two"   style="margin-left: 10px">{{list.two.lose}}</span> 
                               <span v-else style="margin-left: 10px">-</span>
                                </td>
                             </tr>
                             <tr class="trclass">
                                 <td style="width:6%; background: #F7FBFF; color:#16497C; text-align: center;">竞彩</td>
                                 <td style="width:10%; background:#fff; text-align: center;">3</td>
                                 <td style="width:14%; background:#fff; text-align: center;">
                                      <input v-if="form.p&& form.p == '2.5/3' || form.p == '3'|| form.p == '3.5' || form.p == '3/3.5'" @blur="InputBlurP3(odds3)" class="home_header_ipt" type="text" v-model="odds3" value="odds3"> 
                                      <span v-else style="margin-left: 10px">-</span>
                                 </td>
                                 <td style="width:14%; background:#fff; text-align: center;">
                                       <span  v-if="form.p&& form.p == '2.5/3' || form.p == '3'|| form.p == '3.5' || form.p == '3/3.5'"  style="margin-left: 10px">{{odds3_money}}</span> 
                                         <span v-else >-</span> 
                                 </td>
                                 <td style="width:14%; background:#fff; text-align: center;">
                                     <span   v-if="list.three && qwes == '1'"  style="margin-left: 10px"> {{list.three.bonus}}</span>
                                     <span v-else style="margin-left: 10px">-</span> 
                                 </td>
                                 <td style="width:14%; background:#fff; text-align: center;">
                                      <span    v-if="list.three&& qwes == '1'" style="margin-left: 10px"> {{list.three.win}}</span>
                                      <span v-else style="margin-left: 10px">-</span> 
                                 </td>
                                 <td style="width:14%; background:#fff; text-align: center;">
                                        <span  v-if="list.three&& qwes == '1'" style="margin-left: 10px"> {{list.three.lose}}</span>
                              <span v-else style="margin-left: 10px">-</span> 
                                 </td>
                             </tr>
                             <tr class="trclass">
                                 <td style="width:6%; background: #F7FBFF; text-align: center;"></td>
                                  <td style="width:10%; background:#fff; text-align: center;">4</td>
                                  <td style="width:14%; background:#fff; text-align: center;"> <span>-</span></td>
                                  <td style="width:14%; background:#fff; text-align: center;"> <span>-</span></td>
                                  <td style="width:14%; background:#fff; text-align: center;"> <span>-</span></td>
                                  <td style="width:14%; background:#fff; text-align: center;"> <span>-</span></td>
                                  <td style="width:14%; background:#fff; text-align: center;"> <span>-</span></td>
                             </tr>
                             <tr class="trclass">
                                 <td style="width:6%; background: #F7FBFF; text-align: center;"></td>
                                 <td style="width:10%; background:#fff; text-align: center;">5</td>
                                 <td style="width:14%; background:#fff; text-align: center;"><span>-</span></td>
                                 <td style="width:14%; background:#fff; text-align: center;"><span>-</span></td>
                                 <td style="width:14%; background:#fff; text-align: center;"><span>-</span></td>
                                 <td style="width:14%; background:#fff; text-align: center;"><span>-</span></td>
                                 <td style="width:14%; background:#fff; text-align: center;"><span>-</span></td>
                             </tr>
                             
                                <tr class="trclass">
                                 <td style="width:6%; background: #FEF6E5; color:#CC6B00; text-align: center;">crown</td>
                                 <td style="width:10%; background:#FEF6E5; text-align: center;">大{{form.p }}</td> 
                                 <td style="width:14%; background:#FEF6E5; text-align: center;" >
                                      <input @blur="InputBlurP8(odds_cr)" class="home_header_ipt" type="text" v-model="odds_cr" value="odds_cr"> </td>
                                 <td style="width:14%; background:#FEF6E5; text-align: center;" >
                                        <input   class="home_header_ipt" type="text" @blur="InputBlur_odds_cr(odds_cr_money)" v-model="odds_cr_money" value="list.zero.up_money">
                                 </td>
                                 <td style="width:14%; background:#FEF6E5; text-align: center;" v-if="list.crown"> {{list.crown.bonus}} </td>
                                 <td style="width:14%; background:#FEF6E5; text-align: center;" v-if="list.crown"> {{list.crown.win}}</td>
                                 <td style="width:14%; background:#FEF6E5; text-align: center;" v-if="list.crown"> {{list.crown.lose}} </td>
 
                             </tr> 
                        </tbody> 
                    </table>
                        <div class="lirun">
                            <div class="lirun-left">
                                <div class="lirun-text">利润</div>
                                <div  class="lirun-price" v-if="list.other">{{list.other.lirun}}</div>
                            </div>
                            <div class="lirun-right">
                                <div class="lirun-text">返回率</div>
                                <div  class="lirun-price" v-if="list.other">{{list.other.back}}</div>
                            </div>
                        </div>
                    </div>
        <div class="main" v-if="detailsqwe == 2"> 
          <table 
            class="InspectionSituation" 
            border="1px solid #ccc"
            cellspacing="0"
            cellpadding="0"> 
                <thead >
                <tr> 
                    <th colspan="7" style="background: #FFA9A8;">竞彩</th>
                    <th colspan="5" style="background: #FFD703;">crown</th> 
                </tr>
                </thead> 
                <tbody>
                    <tr>
                        <td style="width:9%; background:#E9F7FF;"></td>
                        <td style="width:9%; background:#E9F7FF;">0</td>
                        <td style="width:9%; background:#E9F7FF;">1</td>
                        <td style="width:9%; background:#E9F7FF;">2</td>
                        <td style="width:9%; background:#E9F7FF;">3</td>
                        <td style="width:9%; background:#E9F7FF;">4</td>
                        <td style="width:9%; background:#E9F7FF;">5</td> 
                        <td style="width:19%;">大{{form.p}}</td>
                    </tr>
                      <tr style=" background:#E9F7FF;">
                        <td>赔率</td>
                        <td>  
                            <input @blur="IptBlurP(odds0)" class="home_header_ipt" type="text" v-model="odds0" value="odds0"> 
                        </td> 
                        <td>
                            <input @blur="InputBlurP1(odds1)" class="home_header_ipt" type="text" v-model="odds1" value="odds1"> 
                        </td> 
                        <td>
                            <input  @blur="InputBlurP2(odds2)" class="home_header_ipt" type="text" v-model="odds2" value="odds2"> 
                        </td> 
                        <td>
                            <input v-if="form.p&& form.p == '2.5/3' || form.p == '3'|| form.p == '3.5' || form.p == '3/3.5'" @blur="InputBlurP3(odds3)" class="home_header_ipt" type="text" v-model="odds3" value="odds3"> 
                            <span v-else style="margin-left: 10px">-</span>
                        </td> 
                        <td> 
                          
                            <span  style="margin-left: 10px">-</span>
                        </td> 
                        <td> 
                            <span   style="margin-left: 10px">-</span>
                        </td> 
                        
                        <td>
                            <input @blur="InputBlurP8(odds_cr)" class="home_header_ipt" type="text" v-model="odds_cr" value="odds_cr"> 
                        </td> 
                        
                    </tr>
                  
                    <tr>
                        <td style=" background:#E9F7FF;">下注金额</td> 
                            <td style=" background:#E9F7FF;"> 
                                <span  style="margin-left: 10px">{{odds0_money}}</span>     
                          </td> 
                        <td style=" background:#E9F7FF;"> 
                                  <span   style="margin-left: 10px">{{odds1_money}}</span> 
                            </td> 
                        <td style=" background:#E9F7FF;">  
                            
                                  <span   style="margin-left: 10px">{{odds2_money}}</span> 
                            </td> 
                        <td style=" background:#E9F7FF;">  
                                    <span  v-if="form.p&& form.p == '2.5/3' || form.p == '3'|| form.p == '3.5' || form.p == '3/3.5'"  style="margin-left: 10px">{{odds3_money}}</span> 
                                      <span v-else style="margin-left: 10px">-</span> 
                            </td>  
                        <td style=" background:#E9F7FF;"> 
                                <span   style="margin-left: 10px">-</span> 
                            </td>  
                        <td style=" background:#E9F7FF;">  
                                      <span   style="margin-left: 10px">-</span> 
                            </td> 
                          
                        <td style=" background:#E9F7FF;">  
                              <input   class="home_header_ipt" type="text" @blur="InputBlur_odds_cr(odds_cr_money)" v-model="odds_cr_money" value="list.zero.up_money">
                              
                              </td> 
                    </tr>
                    <tr >
                        <td>奖金</td>
                        <td  >
                              <span   v-if="list.zero && list.zero.bonus" style="margin-left: 10px">{{list.zero.bonus}}</span> 
                              <span v-else style="margin-left: 10px">-</span>
                          </td> 
                        <td>
                            <span  v-if="list.one" style="margin-left: 10px"> {{list.one.bonus}}</span> 
                            <span v-else style="margin-left: 10px">-</span>
                            </td> 
                        <td>
                              <span   v-if="list.two"   style="margin-left: 10px">{{list.two.bonus}}</span> 
                            <span v-else style="margin-left: 10px">-</span>
                            </td> 
                        <td>
                              <span   v-if="list.three && qwes == '1'"  style="margin-left: 10px"> {{list.three.bonus}}</span>
                              <span v-else style="margin-left: 10px">-</span> 
                            </td>  
                        <td> 
                              <span   style="margin-left: 10px">-</span> 
                            </td>  
                        <td> 
                              <span  style="margin-left: 10px">-</span> 
                            </td> 
                        
                        <td  v-if="list.crown">{{list.crown.bonus}}</td> 
                    </tr> 
                    <tr>
                        <td style=" background:#E9F7FF;">赢反水</td>  
                        <td style=" background:#E9F7FF;">
                              <span v-if="list.zero"  style="margin-left: 10px">{{list.zero.win}}</span> 
                              <span v-else style="margin-left: 10px">-</span>
                          </td> 
                        <td style=" background:#E9F7FF;">
                            <span  v-if="list.one" style="margin-left: 10px"> {{list.one.win}}</span>  
                            <span v-else style="margin-left: 10px">-</span>
                            </td> 
                        <td style=" background:#E9F7FF;">
                              <span  v-if="list.two"  style="margin-left: 10px">{{list.two.win}}</span> 
                              <span v-else style="margin-left: 10px">-</span>
                            </td> 
                        <td style=" background:#E9F7FF;">
                              <span    v-if="list.three&& qwes == '1'" style="margin-left: 10px"> {{list.three.win}}</span>
                              <span v-else style="margin-left: 10px">-</span> 
                            </td>  
                        <td style=" background:#E9F7FF;"> 
                              <span  style="margin-left: 10px">-</span> 
                            </td>  
                        <td style=" background:#E9F7FF;"> 
                              <span  style="margin-left: 10px">-</span> 
                            </td> 
                        
                        <td v-if="list.crown" style=" background:#E9F7FF;">  {{list.crown.win}} </td> 
                    </tr>
                    <tr>
                        <td>输反水</td>
                          <td >
                              <span  v-if="list.zero"  style="margin-left: 10px">{{list.zero.lose}}</span> 
                               <span v-else style="margin-left: 10px">-</span>
                          </td> 
                        <td>
                            <span   v-if="list.one"   style="margin-left: 10px"> {{list.one.lose}}</span> 
                             <span v-else style="margin-left: 10px">-</span>
                            
                            </td> 
                        <td>
                              <span  v-if="list.two"   style="margin-left: 10px">{{list.two.lose}}</span> 
                               <span v-else style="margin-left: 10px">-</span>
                            </td> 
                        <td>
                              <span  v-if="list.three&& qwes == '1'" style="margin-left: 10px"> {{list.three.lose}}</span>
                              <span v-else style="margin-left: 10px">-</span> 
                            </td>  
                        <td> 
                              <span  style="margin-left: 10px">-</span> 
                            </td>  
                        <td> 
                              <span  style="margin-left: 10px">-</span> 
                            </td> 
                        
                        
                        <td v-if="list.crown">  {{list.crown.lose}} </td> 
                    </tr>
                    <tr>
                        <td style=" background:#E9F7FF;">利润</td>
                        <td v-if="list.other" colspan="9">{{list.other.lirun}}</td> 
                    </tr>
                    <tr>
                        <td>返还率</td>
                        <td v-if="list.other" colspan="9">{{list.other.back}} %</td>  
                    </tr>
                    
                </tbody>
      </table>
        
      </div>  
     
  </div>
</template>
<script>
export default {
     beforeCreate () {
    this.$pom('details', 'detailsP', this) // 此处需要三个参数，第一个为最大盒子ref名，
//第二个为最大盒子的pc端类名，第三个直接写this，因为插件要找到vue.component实列
  },
  data () {
    
     var rowData = this.$route.params ==null ? this.$route.params : JSON.parse(window.localStorage.getItem("list"));
       
    //  console.log('rowData',rowData);  //当前行数据
 
     return {
       time2:null,
        form: rowData,  
        odds0:rowData.ling, 
        odds1:rowData.yi, 
        odds2:rowData.er, 
        odds3:rowData.san,
        odds4:rowData.si,
        odds5:rowData.wu,
        odds6:rowData.liu,
        odds7:rowData.qi,
        odds_cr:rowData.o,
        odds0_money:"", 
        odds1_money:"", 
        odds2_money:"", 
        odds3_money:"",
        odds4_money:10000,
        odds5_money:10000,
        odds6_money:10000,
        odds7_money:10000,
        odds_cr_money:10000, 
        list:{},
        num:8,
        qwes:0,
         detailsqwe:window.localStorage.getItem("qwe")
    }

  },
   mounted(){ 
    var that = this;  
     that.getListxq();this.qwe()
  },   
  methods: {
     qwe(){
       console.log(this.form.p,"-----");
       if(this.form.p == '2.5/3' || this.form.p == '3' || this.form.p == '3.5' || this.form.p == '3/3.5'){
           this.qwes=1;
           console.log(this.qwes,"====");
       }else{
         this.qwes=0; 
       }

     },
       InputBlur(jc){ 
               if(jc > 0.2){
              this.$message('竞彩返点不能大于0.2,请重新输入');
           }else{  
             let index = this.odds_cr_money.lastIndexOf(".")
                let str =this.odds_cr_money.substring(0,index).replace(/,/g, "");
             this.$axios({
                method: 'get',   
                url: 'http://t.guocaisd.com/newCount.php?p=' + this.form.p + '&odds0=' + this.odds0 + '&odds1='  +  this.odds1 + '&odds2=' + this.odds2 + '&odds3=' + this.odds3 +  '&odds_cr='  + this.odds_cr + '&jc='  + jc +  '&cr='  + this.form.cr +  '&xzje=' + str  +'&xzje_col=' + this.num, 
              }).then(res=>{     
                this.list=res.data; 
                  this.odds0_money=res.data.zero.up_money; 
                 this.odds1_money=res.data.one.up_money; 
                 this.odds2_money=res.data.two.up_money
                 this.odds3_money=res.data.three.up_money; 
              });
               let user = JSON.parse(window.localStorage.getItem("user")); 
              this.$axios({
                    method: 'post', 
                    url: 'http://t.guocaisd.com/list.php',
                    data: this.$qs.stringify({          //axios的post方法访问后端this.qs.stringify(
                     func : 'up',
                     id : user.id, 
                     jc_rebate:jc,
                    }), 
                })
              .then(res=>{                    //请求成功后执行函数
                console.log(res); 
              }); 
           } 
       },
        InputBlurs(cr){
       
           if(cr>0.1){
               this.$message('crown返点不能大于0.1,请重新输入');
           }else{  
             let index = this.odds_cr_money.lastIndexOf(".")
                let str =this.odds_cr_money.substring(0,index).replace(/,/g, ""); 
             this.$axios({
                method: 'get',   
                url: 'http://t.guocaisd.com/newCount.php?p=' + this.form.p + '&odds0=' + this.odds0 + '&odds1='  +  this.odds1 + '&odds2=' + this.odds2 + '&odds3=' + this.odds3 +  '&odds_cr='  + this.odds_cr + '&jc='  + this.form.jc +  '&cr='  + cr +  '&xzje=' + str  +'&xzje_col=' + this.num, 
              }).then(res=>{     
                this.list=res.data; 
                  this.odds0_money=res.data.zero.up_money; 
                 this.odds1_money=res.data.one.up_money; 
                 this.odds2_money=res.data.two.up_money
                 this.odds3_money=res.data.three.up_money; 
              });
               let user = JSON.parse(window.localStorage.getItem("user")); 
              this.$axios({
                    method: 'post', 
                    url: 'http://t.guocaisd.com/list.php',
                    data: this.$qs.stringify({          //axios的post方法访问后端this.qs.stringify(
                     func : 'up',
                     id : user.id,
                     cr_rebate:cr,  
                    }), 
                })
              .then(res=>{                    //请求成功后执行函数
                console.log(res); 
              });
           } 
       },
       IptBlurP(odds){
          let index = this.odds0_money.lastIndexOf(".")
                let str =this.odds0_money.substring(0,index).replace(/,/g, "");
           this.$axios({
                method: 'get',   
                url: 'http://t.guocaisd.com/newCount.php?p=' + this.form.p + '&odds0=' + odds + '&odds1='  +  this.odds1 + '&odds2=' + this.odds2 + '&odds3=' + this.odds3 +  '&odds_cr='  + this.odds_cr + '&jc='  + this.form.jc +  '&cr='  + this.form.cr +  '&xzje=' + str  +'&xzje_col=' + this.num, 
              }).then(res=>{     
                this.list=res.data; 
                 this.odds0_money=res.data.zero.up_money; 
                 this.odds1_money=res.data.one.up_money; 
                 this.odds2_money=res.data.two.up_money
                 this.odds3_money=res.data.three.up_money; 
              })
       },
        InputBlurP1(odds){
           let index = this.odds_cr_money.lastIndexOf(".")
                let str =this.odds_cr_money.substring(0,index).replace(/,/g, "");
            this.$axios({
                method: 'get',   
                url: 'http://t.guocaisd.com/newCount.php?p=' + this.form.p + '&odds0=' + this.odds0 + '&odds1='  +  odds + '&odds2=' + this.odds2 + '&odds3=' + this.odds3 +  '&odds_cr='  + this.odds_cr + '&jc='  + this.form.jc +  '&cr='  + this.form.cr +  '&xzje=' + str  +'&xzje_col=' + this.num, 
              }).then(res=>{     
                this.list=res.data;
                  this.odds0_money=res.data.zero.up_money; 
                 this.odds1_money=res.data.one.up_money; 
                 this.odds2_money=res.data.two.up_money
                 this.odds3_money=res.data.three.up_money; 
              })
       },
        InputBlurP2(odds){
             let index = this.odds_cr_money.lastIndexOf(".")
                let str =this.odds_cr_money.substring(0,index).replace(/,/g, "");
              this.$axios({
                method: 'get',   
                url: 'http://t.guocaisd.com/newCount.php?p=' + this.form.p + '&odds0=' + this.odds0 + '&odds1='  +  this.odds1 + '&odds2=' + odds + '&odds3=' + this.odds3 +  '&odds_cr='  + this.odds_cr + '&jc='  + this.form.jc +  '&cr='  + this.form.cr +  '&xzje=' + str +'&xzje_col=' + this.num, 
              }).then(res=>{     
                this.list=res.data;
                  this.odds0_money=res.data.zero.up_money; 
                 this.odds1_money=res.data.one.up_money; 
                 this.odds2_money=res.data.two.up_money
                 this.odds3_money=res.data.three.up_money; 
              })
       },
        InputBlurP3(odds){
             let index = this.odds_cr_money.lastIndexOf(".")
                let str =this.odds_cr_money.substring(0,index).replace(/,/g, "");
             this.$axios({
                method: 'get',   
                url: 'http://t.guocaisd.com/newCount.php?p=' + this.form.p + '&odds0=' + this.odds0 + '&odds1='  +  this.odds1 + '&odds2=' + this.odds2 + '&odds3=' + odds +  '&odds_cr='  + this.odds_cr + '&jc='  + this.form.jc +  '&cr='  + this.form.cr +  '&xzje=' + str +'&xzje_col=' + this.num, 
              }).then(res=>{     
                this.list=res.data;
                  this.odds0_money=res.data.zero.up_money; 
                 this.odds1_money=res.data.one.up_money; 
                 this.odds2_money=res.data.two.up_money
                 this.odds3_money=res.data.three.up_money; 
              })
       },
       InputBlurP8(odds){
            let index = this.odds_cr_money.lastIndexOf(".")
                let str =this.odds_cr_money.substring(0,index).replace(/,/g, "");
              this.$axios({
                method: 'get',   
                url: 'http://t.guocaisd.com/newCount.php?p=' + this.form.p + '&odds0=' + this.odds0 + '&odds1='  +  this.odds1 + '&odds2=' + this.odds2 + '&odds3=' + this.odds3 +  '&odds_cr='  + odds + '&jc='  + this.form.jc +  '&cr='  + this.form.cr +  '&xzje=' + str  +'&xzje_col=' + this.num, 
              }).then(res=>{     
                this.list=res.data;
                  this.odds0_money=res.data.zero.up_money; 
                 this.odds1_money=res.data.one.up_money; 
                 this.odds2_money=res.data.two.up_money
                 this.odds3_money=res.data.three.up_money; 
              })
       },
       InputBlur_odds0(money){
           this.num=0;
              if(Number(money)){ 
                this.IptBlur(money,this.num)
            }else{
                let index = money.lastIndexOf(".")
                let str =money.substring(0,index).replace(/,/g, "");
                  this.IptBlur(str,this.num)
            }
       },
       InputBlur_odds1(money){
            this.num=1;
               if(Number(money)){ 
                this.IptBlur(money,this.num)
            }else{
                let index = money.lastIndexOf(".")
                let str =money.substring(0,index).replace(/,/g, "");
                  this.IptBlur(str,this.num)
            }
       },
       InputBlur_odds2(money){
            this.num=2;
               if(Number(money)){ 
                this.IptBlur(money,this.num)
            }else{
                let index = money.lastIndexOf(".")
               let str =money.substring(0,index).replace(/,/g, "");
                  this.IptBlur(str,this.num)
            }
       },
       InputBlur_odds3(money){
            this.num=3;
               if(Number(money)){ 
                this.IptBlur(money,this.num)
            }else{
                let index = money.lastIndexOf(".")
                let str =money.substring(0,index).replace(/,/g, "");
                  this.IptBlur(str,this.num)
            }
       },
       InputBlur_odds4(money){
            this.num=4;
               if(Number(money)){ 
                this.IptBlur(money,this.num)
            }else{
                let index = money.lastIndexOf(".")
               let str =money.substring(0,index).replace(/,/g, "");
                  this.IptBlur(str,this.num)
                
            }
       },
       InputBlur_odds5(money){
            this.num=5;
               if(Number(money)){ 
                this.IptBlur(money,this.num)
            }else{
                let index = money.lastIndexOf(".")
               let str =money.substring(0,index).replace(/,/g, "");
                  this.IptBlur(str,this.num)
            }
       },
       InputBlur_odds6(money){
            this.num=6;
               if(Number(money)){ 
                this.IptBlur(money,this.num)
            }else{
                let index = money.lastIndexOf(".")
                let str =money.substring(0,index).replace(/,/g, "");
                  this.IptBlur(str,this.num)
            }
       },
       InputBlur_odds7(money){
            this.num=7;
               if(Number(money)){ 
                this.IptBlur(money,this.num)
            }else{
                let index = money.lastIndexOf(".")
                 let str =money.substring(0,index).replace(/,/g, "");
                  this.IptBlur(str,this.num)
            }
       },
       InputBlur_odds_cr(money){
             this.num=8;
            if(Number(money)){ 
                this.IptBlur(money,this.num)
            }else{
                let index = money.lastIndexOf(".")
                 let str =money.substring(0,index).replace(/,/g, "");
                 console.log(str);
                  this.IptBlur(str,this.num)
            }


           
            
       },
      IptBlur(money,num){
          this.$axios({
                method: 'get',   
                url: 'http://t.guocaisd.com/newCount.php?p=' + this.form.p + '&odds0=' + this.odds0 + '&odds1='  +  this.odds1 + '&odds2=' + this.odds2 + '&odds3=' + this.odds3 +  '&odds_cr='  + this.odds_cr + '&jc='  + this.form.jc +  '&cr='  + this.form.cr +  '&xzje=' + money  +'&xzje_col=' + num, 
              }).then(res=>{     
                this.list=res.data;
                  this.odds0_money=res.data.zero.up_money;
                   this.odds1_money=res.data.one.up_money;
                    this.odds2_money=res.data.two.up_money;
                      this.odds3_money=res.data.three.up_money;
                        this.odds_cr_money=res.data.crown.up_money; 
          })
      },



    onSubmit () {},
      getListxq(){ 
            this.$axios({
                method: 'get',   
                url: 'http://t.guocaisd.com/newCount.php?p=' + this.form.p + '&odds0=' + this.odds0 + '&odds1='  +  this.odds1 + '&odds2=' + this.odds2 + '&odds3=' + this.odds3 +  '&odds_cr='  + this.odds_cr + '&jc='  + this.form.jc +  '&cr='  + this.form.cr +  '&xzje=' + this.odds_cr_money  +'&xzje_col=' + this.num, 
              }).then(res=>{  
                this.list=res.data;
             this.odds0_money=res.data.zero.up_money;
                   this.odds1_money=res.data.one.up_money;
                    this.odds2_money=res.data.two.up_money;
                      this.odds3_money=res.data.three.up_money;
                        this.odds_cr_money=res.data.crown.up_money; 
                 if(res.data.code == 205){
         
              this.$notify({
                          title: '提示',
                          message: '登录超时请重新登录',
                          duration: 3000
                        });
                            this.$router.push('/login')   
                   }
              })
      },
    // backToList () {
    //   this.$router.back(); //返回表格页
    // }
  }
}
</script>
<style lang="scss" scoped>
.detailsM{
   width: 100%;height: 100%;
   font-size: 0.25rem;
   background-color: #F6F6F7;
   display: flex;
   flex-direction: column;
   align-items: center;
   .headers{
     width: 95%;
      height:2.5rem;
     background-color: #ffffff;
     .header_top{
        width: 100%;
        height: 50%;
        display: flex;
        align-items: center; 
        .top_left{
          width: 40%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          color: #6E6E6E;
        }
        .top_right{
          width: 60%;
          height: 100%;
          display: flex;
          align-items: center;
          .right_text{
            font-size: 0.32rem;
            font-weight: bold;
            margin: 0 0.3rem;
            color: #313131;
          }
          .vs{
            font-size: 0.32rem;
            font-weight: bold;
            color: #FE0101;
          }
        }
     }
     .header_buttom{
        width: 100%;
        height: 50%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        .buttom_item{
          width: 2rem;
          height: 1rem;
          background-color: #F7FBFF;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin: 0 0.15rem;
          .item_text{
            color: #717F9A;
            font-size: 0.25rem;
            
          }
          .item_lei{
            width: 80%;
            height:40%;
            background-color: #DEE7F0;
            display: flex;
            align-items: center;
            justify-content: center;
             margin-top: 0.1rem;
            .leixin{
              font-style: 0.2rem;
              font-weight: bold;
            }
          }
          .item_ipt{
            width: 80%;
            height: 40%;
            margin-top: 0.1rem;
            .home_header_ipt{
              width: 100%;
              height: 100%;
              background-color: #DEE7F0;
              border: none;
              font-weight: bold;
            }
          }
        }
     }
   }
    .main{
      width: 95%;
      height: 100%;
      margin-top: 0.2rem;
      overflow: hidden;
      overflow-y:auto;
      overflow-x:auto;
      .InspectionSituationqwe{
          width: 100%;  
        .trclass{
            height: 0.65rem;
        }
         thead{ 
            tr{
              th{
                  font-size: 0.25rem;
              }
            }
          }
          tbody { 
              tr { 
                  td {
                       font-size: 0.25rem;
                  }
              }
          }
      }
      .lirun{
          width: 100%;
          height: 0.8rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 14px;
            margin-top: 0.3rem;
          .lirun-left{
              width: 45%;
              height: 100%;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
                 font-size: 0.25rem;
                 background-color: #fff;
          }
          .lirun-right{
               width: 45%;
              height: 100%;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
                 font-size: 0.25rem;
                 background-color: #fff; 
                
          }
          .lirun-text{ 
            font-size: 0.25rem;
            color: #6E6E6E; 
          }
          .lirun-price{ 
            font-size: 12px; 
            font-weight: bold;
            color: #313131; 
            margin-top: 5px;
          }
      }
      .home_header_ipt{
         width: 80%;
         height: 0.5rem;
         border: none;
         background-color: #DEE7F0;
    } 
      .InspectionSituation {   
        width: 18rem;
        height: 100%;
          thead{
            height: 42px;
            tr{
              th{
                 font-size: 16px;
              }
            }
          }
          tbody { 
              tr {
                height: 42px;
                  td {
                      font-size: 16px;
                      text-align: center; 
                  }
              }
          }
      }
   }
   .home_header_ipt{
         width: 80%;
         height: 80%;
         border: none;
         background-color: #DEE7F0;
    } 

}
.detailsP{
   width: 100%;
   height: 100%;
   font-size: 12px;
   background-color: #F6F6F7;
   display: flex;
   flex-direction: column;
   align-items: center;
   .headers{
     width: 90%;
     height: 201px;
     background-color: #ffffff;
     .header_top{
        width: 100%;
        height: 50%;
        display: flex;
        align-items: center; 
        .top_left{
          width: 40%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          color: #6E6E6E;
        }
        .top_right{
          width: 60%;
          height: 100%;
          display: flex;
          align-items: center;
          .right_text{
            font-size: 26px;
            font-weight: bold;
            margin: 0 20px;
            color: #313131;
          }
          .vs{
            font-size: 26px;
            font-weight: bold;
            color: #FE0101;
          }
        }
     }
     .header_buttom{
        width: 100%;
        height: 50%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        .buttom_item{
          width: 240px;
          height: 74px;
          background-color: #F7FBFF;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin: 0 20px;
          .item_text{
            color: #717F9A;
            font-size: 16px;
          }
          .item_lei{
            width: 100px;
            height: 25px;
            background-color: #DEE7F0;
            display: flex;
            align-items: center;
            justify-content: center;
            .leixin{
              font-style: 14px;
            }
          }
          .item_ipt{
            width: 100px;
            height: 25px;  
            .home_header_ipt{
              width: 100%;
              height: 100%;
              background-color: #DEE7F0;
              border: none;
            }
          }
        }
     }
   }
   .main{
      width: 90%;
      height: 380px;
      margin-top: 50px;
      .InspectionSituation {   
        width: 100%;
        height: 100%;
          thead{
            height: 42px;
            tr{
              th{
                 font-size: 16px;
              }
            }
          }
          tbody { 
              tr {
                height: 42px;
                  td {
                      font-size: 16px;
                      text-align: center; 
                  }
              }
          }
      }
   }
  
   .home_header_ipt{
         width: 80%;
         height: 80%;
         border: none;
         background-color: #DEE7F0;
    } 
}

</style>