<template>
  <div ref="home" class="homeM">   
          
       <div  class="xinzeng" >
             <el-button size="mini" @click="newlyCheck()">新增</el-button> 
               <el-button size="mini"   @click="clicktianxie">填写</el-button>
       </div>
              
      <el-table :data="tableData.slice((currentPage-1)* pageSize,currentPage*pageSize)"  width="100%" height="300" > 
            <el-table-column prop="id" label="序号" width="80">
            </el-table-column>
            <el-table-column type="number" prop="phone" label="手机号" >
            </el-table-column>
             <el-table-column prop="name" label="用户名" >
            </el-table-column>
              <el-table-column prop="pass" label="密码" >
            </el-table-column> 
              <el-table-column prop="st_time" label="起始时间" >
            </el-table-column> 
              <el-table-column prop="end_time" label="终止时间" >
            </el-table-column> 
              <el-table-column prop="jc_rebate" label="竞彩返点" >
            </el-table-column> 
              <el-table-column prop="cr_rebate" label="皇冠返点" >
            </el-table-column> 
            
                <el-table-column prop="" label="操作" width="200">
                    <template slot-scope="scope"> 
           <el-button  size="mini"  @click="redactCheck(scope.$index, scope.row)">编辑</el-button>
           <el-button  size="mini"  type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
        </template> 
            </el-table-column>
        </el-table>
        <!-- 分页器 -->
        <div class="block">
            <el-pagination align='center' @size-change="handleSizeChange" @current-change="handleCurrentChange" 
            :current-page="currentPage" 
            :page-sizes="[15,30,60]" 
            :page-size="pageSize" 
            layout="total, sizes, prev, pager, next" 
            :total="tableData.length">
            </el-pagination> 
        </div> 
           <!-- , jumper -->
        <div class="tanchuang" v-if="falg">
            <span class="Btn" @click="closeClick()">
              <img src="../static/img/shan.png">
            </span>
               <el-form :model="ruleForm" :rules="rules" ref="ruleForm"  class="demo-ruleForm"> 
                <el-form-item label="手机号" prop="phone" class="labels">
                  <el-input v-model="ruleForm.phone"></el-input>
                </el-form-item>
                <el-form-item label="用户名" prop="name">
                  <el-input v-model="ruleForm.name"></el-input>
                </el-form-item>
                <el-form-item label="密码" prop="pass">
                  <el-input v-model="ruleForm.pass"></el-input>
                </el-form-item> 
                <el-form-item label="起始时间" required>
                  <el-col :span="16">
                    <el-form-item prop="st_time">
                      <el-date-picker type="date" placeholder="选择日期" v-model="ruleForm.st_time" style="width: 100%;"></el-date-picker>
                    </el-form-item>
                  </el-col> 
                </el-form-item>
                <el-form-item label="终止时间" required>
                  <el-col :span="16">
                    <el-form-item prop="end_time">
                      <el-date-picker type="date" placeholder="选择日期" v-model="ruleForm.end_time" style="width: 100%;"></el-date-picker>
                    </el-form-item>
                  </el-col>  
                </el-form-item>
                   <el-form-item label="竞彩返点" prop="jc_rebate">
                  <el-input v-model="ruleForm.jc_rebate"></el-input>
                </el-form-item>
                   <el-form-item label="皇冠返点" prop="cr_rebate">
                  <el-input v-model="ruleForm.cr_rebate"></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button class="tijiao" type="primary" @click="submitForm('ruleForm')">提交</el-button>
                  <el-button @click="resetForm('ruleForm')">重置</el-button>
                </el-form-item>
              </el-form>
                
        </div>
  </div>



</template>

<script>
// @ is an alias to /src 
export default {
    beforeCreate () {
    this.$pom('home', 'homeP', this) // 此处需要三个参数，第一个为最大盒子ref名，
//第二个为最大盒子的pc端类名，第三个直接写this，因为插件要找到vue.component实列
  },
  name: "Home",
  components: { 
  },
  data(){
     var checkname = (rule, value, callback) => {
      if (value != "") {
          if(this.nameRules()){
            callback(new Error("用户名已存在，请重新输入"))
          }else{
            callback()
          } 
      }
      callback();
    };
   var checkphone = (rule, value, callback) => {
      if (value != "") {
          if(this.phoneRules()){
            callback(new Error("手机号已存在，请重新输入"))
          }else{
            callback()
          }
      
      }
      callback();
    };
       return {
          tableData: [], 
      ruleForm: { 
              st_time: '',
              end_time: '',
              cr_rebate: "",  
              is_admin: "",
              jc_rebate: "",
              name: "",
              pass: "",
              phone: "", 
        },
        rules: {
          name: [
            { required: true, message: '请输入用户名', trigger: 'blur' },
            { min: 5, max: 12, message: '长度在 5 到 12 个字符', trigger: 'blur' },
             { validator: checkname, trigger: "blur" },
          ],
          phone: [
            { required: true, message: '请输入手机号', trigger: 'blur' },
            { min: 11, max: 11, message: '长度在11位的数字', trigger: 'blur' },
             { validator: checkphone, trigger: "blur" },
          ],
          pass: [
            { required: true, message: '请输入密码', trigger: 'blur' },
            { min: 6, max: 11, message: '长度在 6 到 11 个字符', trigger: 'blur' }
          ],
          
        }, 
      
         falg:false,
         falgs:true,
          currentPage: 1, // 当前页码
          total: 0, // 总条数
          pageSize: 15 // 每页的数据条数
                };
            },

   
 mounted(){ 
    var that = this;
     that.getList();
  
  },

 methods: {
     clicktianxie(){
              let routeUrl = this.$router.resolve({
          path: "/winanddraw",
        
        });
         window.open(routeUrl.href, '_blank') //关键在此
        },
      newlyCheck(){
         this.falg=true;
        this.falgs=true;
         this.ruleForm = {};
        
      },
      closeClick(){
       this.falg=false;
      },
      redactCheck(index,row){
         this.falg=true;
          this.falgs=false; 
          this.ruleForm=row; 
      },
      
     submitForm(formName) {
         // 18612366413
      
        this.$refs[formName].validate((valid) => {
          if (valid) {
            
               this.falg=false;
           if(this.falgs){
              let st_time=this.msToDate(this.ruleForm.st_time).hasTime;
              let end_time=this.msToDate(this.ruleForm.end_time).hasTime; 
                 console.log(this.ruleForm);
                this.$axios({
                    method: 'post',
                    url: 'http://t.guocaisd.com/list.php',
                     data: this.$qs.stringify({          //axios的post方法访问后端this.qs.stringify(
                     func : 'add',
                     add_time: this.ruleForm.add_time,
                      cr_rebate: this.ruleForm.cr_rebate ,
                      end_time: end_time, 
                        id: this.ruleForm.id,
                      is_admin: this.ruleForm.is_admin ,
                      jc_rebate: this.ruleForm.jc_rebate ,
                      name: this.ruleForm.name ,
                      pass: this.ruleForm.pass ,
                      phone: this.ruleForm.phone ,
                      st_time:st_time
                    }), 
                })
                .then(res=>{   //请求成功后执行函数  
                 console.log(res);
                    this.getList();
                     this.falg=false;
                       if(res.data.code == 205){
          
                            this.$router.push('/login')   
                   }
                })
           }else{
              let st_time=this.msToDate(this.ruleForm.st_time).hasTime;
              let end_time=this.msToDate(this.ruleForm.end_time).hasTime; 
                 console.log(this.ruleForm);
             this.$axios({
                    method: 'post',
                    url: 'http://t.guocaisd.com/list.php',
                     data: this.$qs.stringify({          //axios的post方法访问后端this.qs.stringify(
                     func : 'up',
                     add_time: this.ruleForm.add_time,
                      cr_rebate: this.ruleForm.cr_rebate ,
                      end_time: end_time, 
                      id: this.ruleForm.id,
                      is_admin: this.ruleForm.is_admin ,
                      jc_rebate: this.ruleForm.jc_rebate ,
                      name: this.ruleForm.name ,
                      pass: this.ruleForm.pass ,
                      phone: this.ruleForm.phone ,
                      st_time:st_time
                    }), 
                })
                .then(res=>{   //请求成功后执行函数   
                console.log(res);
                 this.getList(); 
                  this.falg=false;
                    if(res.data.code == 205){
         
            
                            this.$router.push('/login')   
                   }
                })
           }
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
        this.ruleForm={};
      },
       //验证用户名是否存在
    nameRules() {
       
        if(this.tableData.includes(this.ruleForm.name)){
             this.$message.error("用户名已存在，请重新输入!")
            this.ruleForm.name = ""
        }else{
           return false
        } 
    },
     phoneRules() {
       
        if(this.tableData.includes(this.ruleForm.phone)){
              this.$message.error("手机号已存在，请重新输入!")
            this.ruleForm.phone = ""
        }else{
          return false
        } 
    },
     msToDate(msec) {
    let datetime = new Date(msec);
    let year = datetime.getFullYear();
    let month = datetime.getMonth();
    let date = datetime.getDate();
    let hour = datetime.getHours();
    let minute = datetime.getMinutes();
    let second = datetime.getSeconds();
    let result1 = year +
                 '-' +
                 ((month + 1) >= 10 ? (month + 1) : '0' + (month + 1)) +
                 '-' +
                 ((date + 1) < 10 ? '0' + date : date) +
                 ' ' +
                 ((hour + 1) < 10 ? '0' + hour : hour) +
                 ':' +
                 ((minute + 1) < 10 ? '0' + minute : minute) +
                 ':' +
                 ((second + 1) < 10 ? '0' + second : second);
    let result2 = year +
                 '-' +
                 ((month + 1) >= 10 ? (month + 1) : '0' + (month + 1)) +
                 '-' +
                 ((date + 1) < 10 ? '0' + date : date);
    let result = {
        hasTime: result1,
        withoutTime: result2
    };
    return result;
//     console.log(msToDate(myTime1).hasTime);//--->2017-10-23 17:20:13
// console.log(msToDate(myTime1).withoutTime);//--->2017-10-23
// console.log(msToDate(myTime2).hasTime);//--->2017-09-19 08:00:00
// console.log(msToDate(myTime2).withoutTime);//--->2017-09-19
}, 
handleDelete(index,row){
  console.log(11111,row);
  this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$axios({
      method: 'post',
      url: 'http://t.guocaisd.com/list.php',
        data: this.$qs.stringify({          //axios的post方法访问后端this.qs.stringify(
        func : 'dl',
        id:row.id
      }), 
      }).then(res=>{   //请求成功后执行函数 
          this.tableData=res.data.data; 
          this.getList();
          this.$message({
                  type: 'success',
                  message: '删除成功!'
                });
        })
         
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
     
},
      getList(){
            this.$axios({
                    method: 'post',
                    url: 'http://t.guocaisd.com/list.php'
                })
                .then(res=>{   //请求成功后执行函数 
                 this.tableData=res.data.data;  
                   if(res.data.code == 205){
         
              this.$notify({
                          title: '提示',
                          message: '登录超时请重新登录',
                          duration: 3000
                        });
                            this.$router.push('/login')   
                   }
                })
      },          
                //每页条数改变时触发 选择一页显示多少行
                handleSizeChange(val) {
                    // console.log(`每页 ${val} 条`); 
                    this.pageSize = val;
                },
                //当前页改变时触发 跳转其他页
                handleCurrentChange(val) {
                    // console.log(`当前页: ${val}`);
                    this.currentPage = val;
                }
            } 

};
</script>


<style lang="scss" scoped>
.homeM{
    width: 100%;height: 100%;
   position: relative;
  
   .el-table .el-table__cell{
      padding: 12px 0 ;
    }
    .xinzeng{
    height: 0.5rem;
    margin-left: 62%;
    margin-top: 10px;
    display: flex;
  }
  .el-pagination__total{
    margin-right: 0 !important;
  }
  .el-table{
    padding: 0;
  }
  .tanchuang{ 
    width:90%;
    height: 75%;
    position: fixed;
    left: 5%;
    top: 10%;
    // border-top: 1rem solid #fff;
    background: #E7EAED;
    border-radius:10px;
    z-index: 999;
     .Btn{
        position: absolute;
        top:0.1rem;
        left: 90%;  
        z-index: 999;
      }
   
    .el-form{
      width: 100%;
      height: 100%;
      padding:13% 0 0  5%;
    
      .el-form-item{
        margin-bottom: 10px;
        
        .el-form-item__label{
          width: 100%;
           
        }
        .el-input{
          width: 70%;
            font-size: 0.5rem;
        }
        
      }
    } 
  }
  

}
 
.homeP{ 
  font-size: 12px !important;
   position: relative;
   .el-table .el-table__cell{
      padding: 12px 0 ;
    }
    .xinzeng{
      width: 100px !important;
    margin-left: 90%;
    margin-top: 10px;
    display: flex;
  }
  @media screen and (min-width:300px) and (max-width: 1200px){
  .el-table__body-wrapper{
    overflow-x: auto !important;
  }
}
 
  .tanchuang{ 
    width: 30%;
    height: 70%;
    position: fixed;
    left: 35%;
    top: 10%;
    // border-top: 1rem solid #fff;
    background: #E7EAED;
    border-radius:10px;
    z-index: 999;
     .Btn{
        position: absolute;
        top:2px;
        left: 90%; 
        font-size: 12px;
        z-index: 999;
      }
   
    .el-form{
      width: 100%;
      height: 100%;
      padding:13% 0 0  5%;
      .el-form-item{
        margin-bottom: 10px;
        .el-form-item__label{
          width: 100%;
           
        }
        .el-input{
          width: 77%;
        }
        
      }
    } 
  }
  

}
 

 
  
</style>
