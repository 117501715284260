export default {
    install (vue) {
      vue.prototype.$pom = (ref, p, that) => { //
        if ( //此处用来检测是否为移动端
          navigator.userAgent.match(/Mobi/i) ||
              navigator.userAgent.match(/Android/i) ||
              navigator.userAgent.match(/iPad/i) ||
              navigator.userAgent.match(/iPhone/i) 

        ) {
          window.localStorage.setItem("qwe",1);
          return false
        } else {
          that.$nextTick(() => {//如果检测到为pc端，就替换最大盒子的类名
            that.$refs[ref].className = p
          })
          window.localStorage.setItem("qwe",2);
        }
      }
    }
  }