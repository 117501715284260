
<template>

  <div ref="home" class="homeM">   
       <div class="home_header">
            <div class="header_top">
                <div class="top_left"> 
                  <el-button size="mini" class="danBtns" @click="clickBtns">大小球</el-button>
                  <el-button size="mini" class="danBtns" @click="clickBtn">单双</el-button>
                  <el-button size="mini" type="primary"   class="danBtns" >大小球（盈利）</el-button>
                </div>
                <div class="top_right">
                    <div class="home_header_text">
                          流量返点: 
                          <input @blur="InputBlur(jc)" class="home_header_ipt" type="text" v-model="jc" value="jc">
                    </div>
                    <div class="home_header_text">
                            皇冠返点: 
                            <input  @blur="InputBlurs(cr)" class="home_header_ipt" type="text" v-model="cr" value="cr">
                    </div>
                </div>
            </div>
            <div class="header_bottom">
                   <div>体彩更新时间: {{jc_time}}  </div> 
                   <div>皇冠更新时间: {{cr_time}}</div>
            </div>
       </div>
      <div class="home_amin"> 
         <el-table  :data="tableData.slice((currentPage-1)*pageSize,currentPage*pageSize)" 
          v-loading="loading"
          height="450"
           :row-class-name="tableRowClassName">>           
                                                            <!-- :width="isPC ? '500' : '110'" -->
            <el-table-column prop="start_time" label="时间" width="100"> 
            </el-table-column>
            <el-table-column prop="xingqi" label="竞彩ID" >
            </el-table-column>
              <el-table-column prop="" label="平台" >crown
            </el-table-column>
              <el-table-column prop="saishi" label="联赛"  >
            </el-table-column>
              <el-table-column prop="zhudui" label="主队" >
            </el-table-column>
              <el-table-column prop="kedui" label="客队">
            </el-table-column>
              <el-table-column prop="p" label="类型">
                  <template slot-scope="scope"> 
                    <span v-if="scope.row.p&&scope.row.p=='2' || scope.row.p=='3'  ">让0</span>
                    <span v-if="scope.row.p&&scope.row.p=='2/2.5' || scope.row.p=='3/3.5' ">让平半</span>
                    <span v-if="scope.row.p&&scope.row.p=='1.5' || scope.row.p=='2.5' || scope.row.p=='3.5'">生死</span>
                    <span v-if="scope.row.p&&scope.row.p=='1.5/2' || scope.row.p=='2.5/3' ">受让平半</span> 
                  </template>
            </el-table-column>
              <el-table-column  prop="ling" label="0" >
                  <template slot-scope="scope"> 
                    <span>{{ scope.row.ling }}</span> 
                  </template>
            </el-table-column>
              <el-table-column prop="yi" label="1" >
                  <template slot-scope="scope"> 
                    <span>{{ scope.row.yi}}</span> 
                  </template>
            </el-table-column>
              <el-table-column prop="er" label="2" >
                  <template slot-scope="scope"> 
                    <span>{{ scope.row.er }}</span> 
                  </template>
            </el-table-column>
               <el-table-column prop="san" label="3" >
                   <template slot-scope="scope"> 
                    <span v-if="scope.row.p&& scope.row.p == '2.5/3' || scope.row.p == '3'|| scope.row.p == '3.5'  || scope.row.p == '3/3.5'">{{ scope.row.san }}</span>
                    <span v-else>-</span>
                  </template>
            </el-table-column>
            <el-table-column prop="si" label="4" width="75">
                   <template slot-scope="scope"> 
                     <span v-if="scope.row.p&& scope.row.p > '3.5'">-</span> <!-- {{ scope.row.si }} --> 
                    <span v-else>-</span>
                  </template>
            </el-table-column>
             
       
               <el-table-column  prop="p" label="外盘口" > 
                  <template slot-scope="scope"> 
                     <span v-if="scope.row.p">大{{ scope.row.p}}</span>
                  </template>
            </el-table-column>
               <el-table-column prop="sw" label="外水位" >
                  <template slot-scope="scope"> 
                   <span v-if="scope.row.o">{{ scope.row.o }}</span>
                  </template>
            </el-table-column>
               <el-table-column prop="" label="利润率" >
                    <template slot-scope="scope">                             
                     <span class="liyl">{{scope.row.back }}</span>
                  </template>
            </el-table-column>
                <el-table-column prop="" label="操作" >
                    <template slot-scope="scope">
          <el-button  type="text" size="small" @click="showCheck(scope.row,'Details')">详情</el-button> 
        </template> 
            </el-table-column>
        </el-table> 
     </div>
        <div class="block">
            <el-pagination align='center' @size-change="handleSizeChange" @current-change="handleCurrentChange" 
            :current-page="currentPage" 
            :page-sizes="[20,30,40]" 
            :page-size="pageSize" 
            layout="total, sizes, prev, pager, next" 
            :total="tableData.length">
            </el-pagination>
        </div> 
         <div class="pop-up" v-if="flagbox">
            <div class="pop-up_box">
                     <div class="pop-up_box_text">今日无赛事</div>
                     <div class="pop-up_box_btn">
                          <div class="queding" @click="queBtn">确定</div>
                     </div>
            </div>
        </div>

 
  </div>
</template>
 
<script>
// @ is an alias to /src 
export default {
     beforeCreate () {
    this.$pom('home', 'homeP', this) // 此处需要三个参数，第一个为最大盒子ref名，
//第二个为最大盒子的pc端类名，第三个直接写this，因为插件要找到vue.component实列
  },
  name: "Home",
  components: { 
  },
  data(){
       return {
          flagbox:false,
          screenWidth: 0,
          isPC: this.isPCNot(),
         loading: true,
          jc:"",
          cr:"", 
          tableData: [ 
          ],
          currentPage: 1, // 当前页码
          total: 0, // 总条数
          pageSize: 20,// 每页的数据条数
          time2:null,
          cr_time:"",
          jc_time:""

                };
            },
    watch: {
        screenWidth: {
            handler: function (val) {
                if (val < 750) {
                    this.isPC = false
                } else {
                    this.isPC = true
                }
                console.log(this.isPC);
            },
        },

    }, 
 mounted(){  
    this.screenWidth = document.body.clientWidth
        window.onresize = () => {
            return (() => {
                this.screenWidth = document.body.clientWidth
            })()
        };
     this.get_user();
   
  }, 
  
 methods: {
     queBtn(){
                this.flagbox=false
            },
            // eslint-disable-next-line
            tableRowClassName({row, rowIndex}) { 
                  if (rowIndex %2 == 0) {
                    return 'warning-row';
                  } 
                  return '';
                },
isPCNot() {
            var userAgentInfo = navigator.userAgent;
            var Agents = ["Android", "iPhone",
                "SymbianOS", "Windows Phone",
                "iPad", "iPod"];
            var flag = true;
            for (var v = 0; v < Agents.length; v++) {
                if (userAgentInfo.indexOf(Agents[v]) > 0) {
                    flag = false;
                    break;
                }
            }
            return flag;
        },
        get_user(){
           let user = JSON.parse(window.localStorage.getItem("user")); 
               this.$axios({
                    method: 'post', 
                    url: 'http://t.guocaisd.com/list.php',
                    data: this.$qs.stringify({          //axios的post方法访问后端this.qs.stringify(
                     func:'get_user', 
                     id : user.id
                    }), 
                })
              .then(res=>{                    //请求成功后执行函数 
                this.jc=res.data.data.jc_rebate;
                this.cr=res.data.data.cr_rebate;  
                      this.$axios({
                    method: 'post', 
                    url: 'http://t.guocaisd.com/dataCheck.php',
                    data: this.$qs.stringify({          //axios的post方法访问后端this.qs.stringify(
                     func:'get_user', 
                     id : user.id
                    }), 
                })
              .then(res=>{                    //请求成功后执行函数  
                  if(res.data.code == 200){
                         this.getList(this.jc,this.cr); 
                  }else{
                       this.flagbox=true
                       this.loading=false;
                     
                  } 
              });
                    if(res.data.code == 205){ 
                            this.$router.push('/login')   
                   } 
              }); 
        },
        clickBtn(){
        //  this.$router.push('/oddeven') 
          
        let routeUrl = this.$router.resolve({
          path: "/oddeven",
        
        });
         window.open(routeUrl.href, '_blank') //关键在此
        },
         clickBtns(){
         let routeUrl = this.$router.resolve({
          path: "/home",
        
        });
         window.open(routeUrl.href, '_blank') //关键在此
        },
       InputBlur(jc){ 
           if(jc > 0.2){
              this.$message('竞彩返点不能大于0.2,请重新输入');
           }else{ 
               this.$axios({
                    method: 'post', 
                    url: 'http://t.guocaisd.com/getdataYL.php',
                    data: this.$qs.stringify({          //axios的post方法访问后端this.qs.stringify(
                     poolCode: "ttg",
                     channel: "c",
                     cr:this.cr,
                     jc:jc,
                      function:"list" 
                    }), 
                })
              .then(res=>{                    //请求成功后执行函数 
                  this.tableData=res.data.data.list; 
                  this.cr_time=res.data.data.cr_time.substr(0,10); 
                  this.jc_time=res.data.data.jc_time.substr(0,10);
                   this.loading=false;
                     if(res.data.code == 205){
         
            
                            this.$router.push('/login')   
                   }
              }); 
              let user = JSON.parse(window.localStorage.getItem("user")); 
              this.$axios({
                    method: 'post', 
                    url: 'http://t.guocaisd.com/list.php',
                    data: this.$qs.stringify({          //axios的post方法访问后端this.qs.stringify(
                     func : 'up',
                     id : user.id, 
                     jc_rebate: this.jc,
                    }), 
                })
              .then(res=>{                    //请求成功后执行函数
                console.log(res); 
                  if(res.data.code == 205){
          
                            this.$router.push('/login')   
                   }
              });  
           }
        
       },
       InputBlurs(cr){ 
           if(cr>0.1){
               this.$message('皇冠返点不能大于0.1,请重新输入');
           }else{ 
              this.$axios({
                    method: 'post', 
                    url: 'http://t.guocaisd.com/getdataYL.php',
                    data: this.$qs.stringify({          //axios的post方法访问后端this.qs.stringify(
                     poolCode: "ttg",
                     channel: "c",
                     cr:cr,
                     jc:this.jc,
                      function:"list" 
                    }), 
                })
              .then(res=>{                    //请求成功后执行函数 
                  this.tableData=res.data.data.list; 
                  this.cr_time=res.data.data.cr_time.substr(0,10); 
                  this.jc_time=res.data.data.jc_time.substr(0,10);
                   this.loading=false;
                     if(res.data.code == 205){
         
             
                            this.$router.push('/login')   
                   }
            
              });
                let user = JSON.parse(window.localStorage.getItem("user")); 
              this.$axios({
                    method: 'post', 
                    url: 'http://t.guocaisd.com/list.php',
                    data: this.$qs.stringify({          //axios的post方法访问后端this.qs.stringify(
                     func : 'up',
                     id : user.id,
                     cr_rebate: this.cr,  
                    }), 
                })
              .then(res=>{                    //请求成功后执行函数
                console.log(res); 
                  if(res.data.code == 205){
          
                            this.$router.push('/login')   
                   }
              });
           }
        
       },
      getList(jc,cr){   
            this.$axios({
                    method: 'post', 
                    url: 'http://t.guocaisd.com/getdataYL.php',
                    data: this.$qs.stringify({          //axios的post方法访问后端this.qs.stringify( 
                     cr:cr,
                     jc:jc,
                      function:"list" 
                    }), 
                })
              .then(res=>{                    //请求成功后执行函数  
                  this.tableData=res.data.data.list; 
                  console.log(res.data.data.list);
                  this.cr_time=res.data.data.cr_time.substr(0,10); 
                  this.jc_time=res.data.data.jc_time.substr(0,10);
                  this.loading=false;
                    if(res.data.code == 205){
         
              this.$notify({
                          title: '提示',
                          message: '登录超时请重新登录',
                          duration: 3000
                        });
                            this.$router.push('/login')   
                   }
              })
      },
      showCheck (row) { 
         
            // this.$router.push({  // 跳转到详情页
            //             name: name,
            //             params: {
            //               jc:this.jc,
            //               cr:this.cr,
            //               ...row
            //             }
            //           }); 
             let params= {
                          jc:this.jc,
                          cr:this.cr,
                          ...row
                        };
        let routeUrl = this.$router.resolve({
          path: "/detailsThree",
          
          query: {
            params: JSON.stringify(params)
          }
        });

             window.open(routeUrl.href, '_blank') //关键在此

           window.localStorage.setItem("list",JSON.stringify({jc:this.jc, cr:this.cr, ...row}));
         
         
        }, 
        //每页条数改变时触发 选择一页显示多少行
        handleSizeChange(val) { 
            this.pageSize = val; 
          
        },
        //当前页改变时触发 跳转其他页
        handleCurrentChange(val) { 
            this.currentPage = val; 
        }
    } 

};
</script>
<style lang="scss" >
// scoped
 .homeM{ 
   width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.33rem; 
    .pop-up{
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background:rgba(0,0,0,0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    .pop-up_box{
       width: 6rem;
       height: 3rem;
       background-color: #fff;
       border-radius: 15px;
       .pop-up_box_text{
         width: 100%;
         height: 0.5rem;
         display: flex;
         align-items: center;
         justify-content: center;
         font-size: 20px;
         font-weight:bold;
         margin-top: 0.5rem;
       }
       .pop-up_box_btn{
         width: 100%;
         height: 0.5rem;
         display: flex;
         align-items: center;
         justify-content: center;
         font-size: 20px;
         font-weight: bold;
         margin-top: 1rem;
         .queding{
           width: 100px;
           height: 0.8rem;
           display: flex;
           align-items: center;
           justify-content: center;
           border: 2px solid rgb(162, 149, 149);
           background-color: #eee;
           border-radius:0.2rem;
         }
       }

    }
  }
  .home_header{ 
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .header_top{
      width: 100%;
       .top_left{
          width: 100%;
          height: 1rem;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .top_right{
          width: 100%;
          height: 1rem;
          display: flex;
          align-items: center;
          justify-content: center;
          .home_header_text{
            margin: 0 0.5rem;
            font-weight: bold;
            .home_header_ipt{
              width: 1rem;
              height: 0.8rem;
              background-color: #DEE7F0;
              border: none;
              border-radius: 0.1rem;
              
            }
          }
          
        }
    }
    .header_bottom{
      width: 100%;
      height: 1rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-weight: bold;
    }
   
  }
  .home_amin{
    width: 100%;
    height: calc(100% - 4rem);
    .el-table{ 
       height:100%;
    }
  }
}
 
.homeP{ 
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px; 
   .pop-up{
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background:rgba(0,0,0,0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    .pop-up_box{
       width: 400px;
       height: 200px;
       background-color: #fff;
       border-radius: 15px;
       .pop-up_box_text{
         width: 100%;
         height: 50px;
         display: flex;
         align-items: center;
         justify-content: center;
         font-size: 20px;
         font-weight:bold;
         margin-top: 30px;
       }
       .pop-up_box_btn{
         width: 100%;
         height: 50px;
         display: flex;
         align-items: center;
         justify-content: center;
         font-size: 20px;
         font-weight: bold;
         margin-top: 40px;
         .queding{
           width: 100px;
           height: 40px;
           display: flex;
           align-items: center;
           justify-content: center;
           border: 2px solid rgb(162, 149, 149);
           background-color: #eee;
           border-radius:7px;
         }
       }

    }
  }
  .home_header{
    width: 100%;
    height: 160px;
    display: flex;
    flex-direction: column;
    .header_top{
      height: 60px;
      display: flex;
      justify-content: space-between;
      .top_left{
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .top_right{
         width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
       .home_header_text{
         margin: 0 10px;
         font-weight: bold;
       }
      }
      .home_header_ipt{
        width: 70px;
        height: 40px;
        background-color: #DEE7F0;
        border:none;
        border-radius: 7px;
      }
    }
    .header_bottom{
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight:bold;
      div{
        margin: 0 10px;
        font-weight: bold;
      }
    }
  }
  .home_amin{
    width: 90%;
    height: calc(100% - 210px); 
    .el-table{
      height: 100% !important;
    }
  }

}
 

 

</style>
