import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant';
import 'vant/lib/index.css';
import pom from '@/utils/pcOrMobile' // 引入pc与mobile检测文件
import '@/utils/rem' // 引入移动端rem适配 
import axios from "./config/axios"   
import qs from 'qs'; 
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css' 
Vue.use(ElementUI)
Vue.use(pom) // 插件增强vue功能
Vue.use(Vant);
Vue.prototype.$qs = qs
Vue.config.productionTip = false
Vue.prototype.$axios = axios
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')