import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Details from '../views/Details.vue'
import Detailsone from '../views/Detailsone.vue' 
import Management from '../views/Management.vue' 
import Oddeven from '../views/Oddeven.vue' 
import Winanddraw from '../views/Winanddraw.vue' 
import Profit from '../views/Profit.vue' 
import DetailsThree from '../views/DetailsThree.vue'   
Vue.use(VueRouter)
 
const routes = [{
        path: '/',
        name: 'login',
        redirect: "/login"
    },
    {
        path: '/home',
        name: 'Home',
        component: Home
    },
    {
        path: '/login',
        name: 'Login',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Login.vue')
    },
    {
        path: '/management',
        name: 'Management',
        component: Management
      },
      
    {
        path: '/details',
        name: 'Details',
        component: Details
      },
      {
        path: '/detailsone',
        name: 'Detailsone',
        component: Detailsone
      },
    
      {
          path: '/oddeven',
          name: 'Oddeven',
          component: Oddeven
      },
      {
            path: '/winanddraw',
            name: 'Winanddraw',
            component: Winanddraw
      },
      {
            path: '/profit',
            name: 'Profit',
            component: Profit
      },
      {
            path: '/detailsThree',
            name: 'DetailsThree',
            component: DetailsThree
      }
        
]
 

const router = new VueRouter({
    // mode: 'history',
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
});
 
router.beforeEach((to, from, next) => {
  const user = JSON.parse( window.localStorage.getItem("user"));
   
  if (!user && to.path !== '/login') {  
      next('/login');
  } else {
      next();
  }
}); 
export default router
